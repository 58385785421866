import React, { useState } from "react";
import emailjs from "emailjs-com";
import emailjsConfig from "../emailConfig";

import "./Footer.css";

import Button from "../Button/Button";
import FacebookIcon from "@material-ui/icons/Facebook";
import PhoneIcon from "@material-ui/icons/Phone";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";

const Footer = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [info, setInfo] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        emailjsConfig.SERVICE_ID,
        emailjsConfig.TEMPLATE2_ID,
        e.target,
        emailjsConfig.USER_ID
      )
      .then((res) => {
        console.log(res.text);
      })
      .then((res) => {
        setName("");
        setEmail("");
        setInfo("");
      })
      .catch((err) => {
        console.log(err.text);
      });
  };

  if (props.french) {
    return (
      <div className="footer">
        <div className="footer__top">
          <form onSubmit={sendEmail}>
            <h1>Contactez-Nous!</h1>
            <input
              name="from_name"
              type="text"
              placeholder="Nom"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            <input
              name="reply_to"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <textarea
              name="message"
              type="text"
              placeholder="Écrivez-Nous un Message!"
              value={info}
              onChange={(e) => setInfo(e.target.value)}
            />
            <Button btnStyle="footer" type="submit">
              Envoyer
            </Button>
          </form>
        </div>
        <hr></hr>
        <div className="footer__bottom">
          <div className="footer__start">
            <p>© Savoie Preservation Solutions 2012-2020</p>
            <p>
              <AlternateEmailIcon /> info@savoieps.com
            </p>
            <p>
              <PhoneIcon /> +33 6 81 18 11 73
            </p>
          </div>
          <div className="footer__end">
            <a
              href="https://www.facebook.com/SavoiePreservationSolutions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon fontSize="large" />
            </a>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="footer">
        <div className="footer__top">
          <form onSubmit={sendEmail}>
            <h1>Get in Touch!</h1>
            <input
              name="from_name"
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            <input
              name="reply_to"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <textarea
              name="message"
              type="text"
              placeholder="Write us a Message"
              value={info}
              onChange={(e) => setInfo(e.target.value)}
            />
            <Button btnStyle="footer" type="submit">
              Send
            </Button>
          </form>
        </div>
        <hr></hr>
        <div className="footer__bottom">
          <div className="footer__start">
            <p>© Savoie Preservation Solutions 2012-2020</p>
            <p>
              <AlternateEmailIcon /> info@savoieps.com
            </p>
            <p>
              <PhoneIcon /> +33 6 81 18 11 73
            </p>
          </div>
          <div className="footer__end">
            <a
              href="https://www.facebook.com/SavoiePreservationSolutions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon fontSize="large" />
            </a>
          </div>
        </div>
      </div>
    );
  }
};

export default Footer;
