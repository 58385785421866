import React, { useState } from "react";
import "./App.css";

import Navbar from "./Navbar/Navbar";
import Slideshow from "./Slideshow/Slideshow";
import Sections from "./Sections/Sections";
import ContactForm from "./ContactForm/ContactForm";
import Footer from "./Footer/Footer";
import Sidedrawer from "./Sidedrawer/Sidedrawer";

const App = () => {
  const [french, setFrench] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const [showSidedrawer, setShowSidedrawer] = useState(false);
  const [showGwd, setShowGwd] = useState(false);

  const showContactFormHandle = () => {
    setShowContactForm(true);
  };

  const showSidedrawerHandle = () => {
    setShowSidedrawer(!showSidedrawer);
  };

  const toggleGwdHandle = () => {
    setShowGwd(!showGwd);
  };

  return (
    <div className="app">
      <Navbar
        french={french}
        contactClicked={showContactFormHandle}
        toggleClicked={showSidedrawerHandle}
        toggleClass={showSidedrawer}
        setFrench={() => setFrench(true)}
        setEnglish={() => setFrench(false)}
      />
      <Sidedrawer
        show={showSidedrawer}
        french={french}
        sectionSelected={showSidedrawerHandle}
        contactClicked={showContactFormHandle}
        setFrench={() => setFrench(true)}
        setEnglish={() => setFrench(false)}
      />
      <Slideshow />
      <ContactForm
        show={showContactForm}
        french={french}
        backdropClicked={() => setShowContactForm(false)}
        exitFormClicked={() => setShowContactForm(false)}
      />
      <Sections french={french} buttonClicked={showContactFormHandle} />

      <Footer toggleGwd={toggleGwdHandle} french={french} />
    </div>
  );
};

export default App;
