import React, { useState } from "react";
import emailjs from "emailjs-com";
import emailjsConfig from "../emailConfig";
import "./ContactForm.css";

import Aux from "../hoc/Aux/Aux";
import Button from "../Button/Button";
import Backdrop from "../Backdrop/Backdrop";

import CloseIcon from "@material-ui/icons/Close";

const ContactForm = (props) => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [postCode, setPostCode] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [establishment, setEstablishment] = useState("");
  const [info, setInfo] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        emailjsConfig.SERVICE_ID,
        emailjsConfig.TEMPLATE_ID,
        e.target,
        emailjsConfig.USER_ID
      )
      .then((res) => {
        console.log(res.text);
      })
      .then((res) => {
        setName("");
        setAddress("");
        setPostCode("");
        setCity("");
        setPhone("");
        setEmail("");
        setType("");
        setEstablishment("");
        setInfo("");
      })
      .catch((err) => {
        console.log(err.text);
      });
  };

  if (props.show && props.french) {
    return (
      <Aux>
        <Backdrop clicked={props.backdropClicked} />
        <div className="contactform">
          <div className="contactform__top">
            <h3>Information Client</h3>
            <CloseIcon onClick={props.exitFormClicked} />
          </div>
          <form onSubmit={sendEmail}>
            <input
              name="from_name"
              type="text"
              placeholder="Nom*"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              name="address"
              type="text"
              placeholder="Adress"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <input
              name="post_code"
              type="text"
              placeholder="Code Postal*"
              value={postCode}
              onChange={(e) => setPostCode(e.target.value)}
              required
            />
            <input
              name="city"
              type="text"
              placeholder="Ville*"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
            <input
              name="phone"
              type="tel"
              placeholder="Téléphone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              name="email"
              type="email"
              placeholder="Email*"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              name="floor_type"
              type="text"
              placeholder="Type de Plancher*"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            />
            <input
              name="establishment"
              type="text"
              placeholder="Établissement"
              value={establishment}
              onChange={(e) => setEstablishment(e.target.value)}
            />
            <textarea
              name="message"
              type="text"
              placeholder="Information Additionel"
              value={info}
              onChange={(e) => setInfo(e.target.value)}
            />
            <p>
              <em>*Champs requis</em>
            </p>
            <Button btnStyle="contact" type="submit">Envoyer</Button>
          </form>
        </div>
      </Aux>
    );
  } else if (props.show) {
    return (
      <Aux>
        <Backdrop clicked={props.backdropClicked} />
        <div className="contactform">
          <div className="contactform__top">
            <h3>Customer Details</h3>
            <CloseIcon onClick={props.exitFormClicked} />
          </div>
          <form onSubmit={sendEmail}>
            <input
              name="from_name"
              type="text"
              placeholder="Name*"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              name="address"
              type="text"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <input
              name="post_code"
              type="text"
              placeholder="Post Code*"
              value={postCode}
              onChange={(e) => setPostCode(e.target.value)}
              required
            />
            <input
              name="city"
              type="text"
              placeholder="City*"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
            <input
              name="phone"
              type="tel"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              name="email"
              type="email"
              placeholder="Email*"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              name="floor_type"
              type="text"
              placeholder="Floor Type*"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            />
            <input
              name="establishment"
              type="text"
              placeholder="Establishment"
              value={establishment}
              onChange={(e) => setEstablishment(e.target.value)}
            />
            <textarea
              name="message"
              type="text"
              placeholder="Additional Information"
              value={info}
              onChange={(e) => setInfo(e.target.value)}
            />
            <p>
              <em>*Required Fields</em>
            </p>
            <Button btnStyle="contact" type="submit">Send</Button>
          </form>
        </div>
      </Aux>
    );
  } else {
    return null;
  }
};

export default ContactForm;
