import React from "react";
import "./Button.css";

const Button = (props) => {
  let btnClass = "";

  if (props.btnStyle === "contact") {
    btnClass = "button__contact";
  } else if (props.btnStyle === "footer") {
      btnClass = "button__footer"
  }

  return (
    <button
      className={btnClass}
      disabled={props.disabled}
      onClick={props.clicked}
    >
      {props.children}
    </button>
  );
};

export default Button;
