import React from 'react';
import './Aux.css';

const Aux = (props) => {
    return (
        <div className="aux">
            {props.children}
        </div>
    )
}

export default Aux
