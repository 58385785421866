import React from "react";
import { Link } from "react-scroll";
import { IconButton } from "@material-ui/core";
import Flag from "react-world-flags";

import LineStyleIcon from "@material-ui/icons/LineStyle";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import EcoIcon from "@material-ui/icons/Eco";
import GrainIcon from "@material-ui/icons/Grain";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";

import "./Sidedrawer.css";

const Sidedrawer = (props) => {
  if (props.show && props.french) {
    return (
      <div className="sidedrawer">
        <ul className="sidedrawer__list" onClick={props.sectionSelected}>
          <Link
            activeClass="sidedrawer__active"
            to="carpet"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <li className="sidedrawer__item" onClick={props.sectionSelected}>
              <LineStyleIcon />
              <p>Tapis</p>
            </li>
          </Link>
          <Link
            activeClass="sidedrawer__active"
            to="stain"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <li className="sidedrawer__item" onClick={props.sectionSelected}>
              <BubbleChartIcon />
              <p>Tache</p>
            </li>
          </Link>
          <Link
            activeClass="sidedrawer__active"
            to="wood"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <li className="sidedrawer__item" onClick={props.sectionSelected}>
              <EcoIcon />
              <p>Bois</p>
            </li>
          </Link>
          <Link
            activeClass="sidedrawer__active"
            to="stone"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <li className="sidedrawer__item" onClick={props.sectionSelected}>
              <GrainIcon />
              <p>Pierre</p>
            </li>
          </Link>
          <Link
            activeClass="sidedrawer__active"
            to="tile"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <li className="sidedrawer__item" onClick={props.sectionSelected}>
              <DashboardIcon />
              <p>Tuile</p>
            </li>
          </Link>
          <Link
            activeClass="sidedrawer__active"
            to="safety"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <li className="sidedrawer__item" onClick={props.sectionSelected}>
              <ReportProblemOutlinedIcon />
              <p>Sécurité</p>
            </li>
          </Link>
          <Link
            activeClass="sidedrawer__active"
            to="testimonials"
            spy={true}
            smooth={true}
            offset={-550}
            duration={200}
          >
            <li className="sidedrawer__item" onClick={props.sectionSelected}>
              <ThumbUpAltIcon />
              <p>Testémonies</p>
            </li>
          </Link>
          <li className="sidedrawer__item" onClick={props.contactClicked}>
            <AlternateEmailIcon />
            <p>Contact</p>
          </li>
        </ul>
        <div className="sidedrawer__languages">
          <IconButton onClick={props.setEnglish}>
            <Flag code="GB" height="16" />
            <p>| EN</p>
          </IconButton>
          <IconButton onClick={props.setFrench}>
            <Flag code="FR" height="16" />
            <p>| FR</p>
          </IconButton>
        </div>
      </div>
    );
  } else if (props.show) {
    return (
      <div className="sidedrawer">
        <ul className="sidedrawer__list" onClick={props.sectionSelected}>
          <Link
            activeClass="sidedrawer__active"
            to="carpet"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <li className="sidedrawer__item" onClick={props.sectionSelected}>
              <LineStyleIcon />
              <p>Carpet</p>
            </li>
          </Link>
          <Link
            activeClass="sidedrawer__active"
            to="stain"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <li className="sidedrawer__item" onClick={props.sectionSelected}>
              <BubbleChartIcon />
              <p>Stain</p>
            </li>
          </Link>
          <Link
            activeClass="sidedrawer__active"
            to="wood"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <li className="sidedrawer__item" onClick={props.sectionSelected}>
              <EcoIcon />
              <p>Wood</p>
            </li>
          </Link>
          <Link
            activeClass="sidedrawer__active"
            to="stone"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <li className="sidedrawer__item" onClick={props.sectionSelected}>
              <GrainIcon />
              <p>Stone</p>
            </li>
          </Link>
          <Link
            activeClass="sidedrawer__active"
            to="tile"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <li className="sidedrawer__item" onClick={props.sectionSelected}>
              <DashboardIcon />
              <p>Tiling</p>
            </li>
          </Link>
          <Link
            activeClass="sidedrawer__active"
            to="safety"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <li className="sidedrawer__item" onClick={props.sectionSelected}>
              <ReportProblemOutlinedIcon />
              <p>Safety</p>
            </li>
          </Link>
          <Link
            activeClass="sidedrawer__active"
            to="testimonials"
            spy={true}
            smooth={true}
            offset={-550}
            duration={200}
          >
            <li className="sidedrawer__item" onClick={props.sectionSelected}>
              <ThumbUpAltIcon />
              <p>Testimonials</p>
            </li>
          </Link>
          <li className="sidedrawer__item" onClick={props.contactClicked}>
            <AlternateEmailIcon />
            <p>Contact</p>
          </li>
        </ul>
        <div className="sidedrawer__languages">
          <IconButton onClick={props.setEnglish}>
            <Flag code="GB" height="16" />
            <p>| EN</p>
          </IconButton>
          <IconButton onClick={props.setFrench}>
            <Flag code="FR" height="16" />
            <p>| FR</p>
          </IconButton>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Sidedrawer;
