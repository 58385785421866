import React, { useEffect } from "react";
import "./Backdrop.css";

const Backdrop = (props) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);
  return <div className="backdrop" onClick={props.clicked}></div>;
};

export default Backdrop;
