import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./Slideshow.css";

const Slideshow = (props) => {
  return (
    <div>
      {props.children}
      <Carousel
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        autoPlay
        className="slideshow"
      >
        <div className="slideshow__slide1"></div>
        <div className="slideshow__slide2"></div>
        <div className="slideshow__slide3"></div>
        <div className="slideshow__slide4"></div>
        <div className="slideshow__slide5"></div>
      </Carousel>
    </div>
  );
};

export default Slideshow;
