import React from "react";
import { Link } from "react-scroll";
import { IconButton } from "@material-ui/core";
import Flag from "react-world-flags";

import BurgerMenu from "../BurgerMenu/BurgerMenu";
import LineStyleIcon from "@material-ui/icons/LineStyle";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import EcoIcon from "@material-ui/icons/Eco";
import GrainIcon from "@material-ui/icons/Grain";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

import logo from "../assets/SPS_LOGO.jpg";

import "./Navbar.css";

const Navbar = (props) => {
  if (props.french) {
    return (
      <div className="navbar">
        <Link to="about" spy={true} smooth={true} offset={-100} duration={500}>
          <div className="navbar__logo">
            <img src={logo} alt="logo" />
          </div>
        </Link>
        <div className="navbar__middle">
          <Link
            activeClass="navbar__active"
            to="carpet"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <div className="navbar__item">
              <LineStyleIcon fontSize="large" />
              <p>Tapis</p>
            </div>
          </Link>

          <Link
            activeClass="navbar__active"
            to="stain"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <div className="navbar__item">
              <BubbleChartIcon fontSize="large" />
              <p>Taches</p>
            </div>
          </Link>
          <Link
            activeClass="navbar__active"
            to="wood"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <div className="navbar__item">
              <EcoIcon fontSize="large" />
              <p>Parquet</p>
            </div>
          </Link>
          <Link
            activeClass="navbar__active"
            to="stone"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <div className="navbar__item">
              <GrainIcon fontSize="large" />
              <p>Pierre</p>
            </div>
          </Link>
          <Link
            activeClass="navbar__active"
            to="tile"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <div className="navbar__item">
              <DashboardIcon fontSize="large" />
              <p>Carrelage</p>
            </div>
          </Link>
          <Link
            activeClass="navbar__active"
            to="safety"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <div className="navbar__item">
              <ReportProblemOutlinedIcon fontSize="large" />
              <p>Sécurité</p>
            </div>
          </Link>
        </div>
        <div className="navbar__end">
          <div className="navbar__links">
            <Link
              activeClass="navbar__active"
              to="testimonials"
              spy={true}
              smooth={true}
              offset={-470}
              duration={200}
            >
              <p>Testémonies</p>
            </Link>
            <p onClick={props.contactClicked}>Contact</p>
          </div>

          <div className="navbar__languages">
            <IconButton onClick={props.setEnglish}>
              <Flag code="GB" height="16" />
              <p>| EN</p>
            </IconButton>
            <IconButton onClick={props.setFrench}>
              <Flag code="FR" height="16" />
              <p>| FR</p>
            </IconButton>
          </div>
        </div>
        <div className="navbar__burger">
          <BurgerMenu
            clicked={props.toggleClicked}
            toggleClass={props.toggleClass}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="navbar">
        <Link to="about" spy={true} smooth={true} offset={-100} duration={500}>
          <div className="navbar__logo">
            <img src={logo} alt="logo" />
          </div>
        </Link>
        <div className="navbar__middle">
          <Link
            activeClass="navbar__active"
            to="carpet"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <div className="navbar__item">
              <LineStyleIcon fontSize="large" />
              <p>Carpet</p>
            </div>
          </Link>
          <Link
            activeClass="navbar__active"
            to="stain"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <div className="navbar__item">
              <BubbleChartIcon fontSize="large" />
              <p>Stains</p>
            </div>
          </Link>
          <Link
            activeClass="navbar__active"
            to="wood"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <div className="navbar__item">
              <EcoIcon fontSize="large" />
              <p>Wood</p>
            </div>
          </Link>
          <Link
            activeClass="navbar__active"
            to="stone"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <div className="navbar__item">
              <GrainIcon fontSize="large" />
              <p>Stone</p>
            </div>
          </Link>
          <Link
            activeClass="navbar__active"
            to="tile"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <div className="navbar__item">
              <DashboardIcon fontSize="large" />
              <p>Tiled Floors</p>
            </div>
          </Link>
          <Link
            activeClass="navbar__active"
            to="safety"
            spy={true}
            smooth={true}
            offset={-120}
            duration={200}
          >
            <div className="navbar__item">
              <ReportProblemOutlinedIcon fontSize="large" />
              <p>Safety</p>
            </div>
          </Link>
        </div>
        <div className="navbar__end">
          <div className="navbar__links">
            <Link
              activeClass="navbar__endactive"
              to="testimonials"
              spy={true}
              smooth={true}
              offset={-470}
              duration={200}
            >
              <p>Testimonials</p>
            </Link>
            <p onClick={props.contactClicked}>Contact</p>
          </div>

          <div className="navbar__languages">
            <IconButton onClick={props.setEnglish}>
              <Flag code="GB" height="16" />
              <p>| EN</p>
            </IconButton>
            <IconButton onClick={props.setFrench}>
              <Flag code="FR" height="16" />
              <p>| FR</p>
            </IconButton>
          </div>
        </div>
        <div className="navbar__burger">
          <BurgerMenu
            clicked={props.toggleClicked}
            toggleClass={props.toggleClass}
          />
        </div>
      </div>
    );
  }
};

export default Navbar;
