import React, { useState } from "react";
import "./Sections.css";

import Button from "../Button/Button";
import RemoveIcon from "@material-ui/icons/Remove";

import softImg1 from "../assets/SectionsImages/rug1.jpeg";
import softImg2 from "../assets/SectionsImages/rug2.jpg";
import softImg3 from "../assets/SectionsImages/wine.jpeg";
import softImg4 from "../assets/SectionsImages/rug3.jpeg";

import hardImg1 from "../assets/SectionsImages/hardfloor.jpg";

import testImg1 from "../assets/SectionsImages/Testimonials/alpine_infusion_logo.jpg";
import testImg2 from "../assets/SectionsImages/Testimonials/ams__optimized.jpg";
import testImg3 from "../assets/SectionsImages/Testimonials/alpine_shine_logo.jpg";
import testImg4 from "../assets/SectionsImages/Testimonials/consensio_logo.png";

const Sections = (props) => {
  const softImages = [softImg1, softImg2, softImg3, softImg4];
  const hardImages = [hardImg1];
  const [softImage, setSoftImage] = useState(softImages[0]);
  const [hardImage, setHardImage] = useState(hardImages[0]);

  if (props.french) {
    return (
      <div className="sections">
        {/* About Section */}
        <div id="about" className="sections__about">
          <div className="sections__aboutcontent">
            <h3>‘Préserver l’Image de votre Établissement.’</h3>
            <p>
              Créée à Méribel en 2012, SPS est spécialisée en nettoyage
              professionnel de tapisserie et sols durs pour la région de Savoie.
              Nous offrons des services de nettoyage pour une multitude de
              surfaces intérieures y compris les tapis, canapés et tissus
              d’ameublement, rideaux, parquet, carrelage et sols en pierre
              naturelle.
            </p>
            <br></br>
            <p>
              Notre équipe est entièrement formée, assurée et équipée des
              meilleures machines et produits sur le marché. Nous sommes fiers
              de fournir un service qui préserve l’image et l’intégrité de
              votre chalet, maison ou établissement.
            </p>
            <br></br>
            <p>
              SPS s’engage à s’approvisionner de produits respectueux de
              l’environnement afin de réduire l’impact environnemental.
            </p>
          </div>
        </div>

        {/*Carpet cleaning Section*/}
        <div id="carpet" className="sections__carpet">
          <div className="sections__carpetcontent">
            <h3>Nettoyage de Tapis</h3>
            <p>
              Au fil du temps, les textiles absorbent des polluants ainsi
              que la poussière, des huiles et autres particules endommageantes.
              Cela réduit l’image et la qualité de vos tapis et meubles tout en
              réduisants leur longévité. Certains de ces polluants pourraient
              également être nocif pour votre santé.
            </p>
            <br></br>
            <p>
              Chez SPS, nous utilisons une technique d’extraction à l’eau chaude
              qui effectue un nettoyage en profondeur des fibres, permettant
              d’extraire toutes les impuretés qui se trouvent dans les tissus.
            </p>
            <br></br>
            <p>
              Notre service remet vos tapis et meubles à leur image original.
              Nous prenons soin de bien maintenir l’intégrité des tissus sans
              laisser de résidus chimiques ou de les endommager.
            </p>
          </div>
        </div>

        {/* Stain Removal Section */}
        <div id="stain" className="sections__stains">
          <div className="sections__stainscontent">
            <h3>Traitement de Taches</h3>
            <p>
              Aucun tapis n’est complètement à l’abri des déversements de
              liquides ou de petits accidents. Votre meilleure option est d’agir
              rapidement. Sans aucun doute, plus les taches restent longtemps
              sur votre textile, plus elles deviennent difficiles à éliminer. La
              plupart des taches de tapis peuvent être traitées, mais les taches
              de tapis tenaces nécessiteront des solutions de détachage
              éprouvées.
            </p>
            <br></br>
            <p>
              Nous possédons le savoir-faire et les compétences nécessaires pour
              éliminer presque toutes les taches. Nous fournirons une estimation
              sans engagement en fonction de vos besoins.
            </p>
          </div>
        </div>
        {/* Carpet Cleaning Process */}
        <div className="sections__softprocess">
          <div className="sections__softleft">
            <div className="sections__softimage">
              <img
                src={softImage}
                alt="Procédure de Nettoyage pour les Tapis"
              />
            </div>
            <div className="sections__softdisclaimer">
              <p>
                <em>
                  Tous les produits utilisés sont sans danger pour les enfants,
                  les animaux domestiques et l’environnement. Les membres de
                  notre équipe sont formés aux techniques de nettoyage afin
                  de fournir un résultat qualitatif et efficace.
                </em>
              </p>
            </div>
          </div>

          <div className="sections__softlist">
            <h3>Procédure de Nettoyage pour les Tapis</h3>
            <ol>
              <li onMouseOver={() => setSoftImage(softImages[0])}>
                <h4>Déterminer le Type de Tissu</h4>
              </li>
              <li onMouseOver={() => setSoftImage(softImages[0])}>
                <h4>Aspiration à Sec</h4>
              </li>
              <li onMouseOver={() => setSoftImage(softImages[2])}>
                <h4>Traitement de Tache, Pré-Pulvérisation et Récurage</h4>
              </li>
              <li onMouseOver={() => setSoftImage(softImages[1])}>
                <h4>Extraction à Eau Chaude(Deep Clean à Vapeur)</h4>
              </li>
              <li onMouseOver={() => setSoftImage(softImages[3])}>
                <h4>Séchage</h4>
              </li>
            </ol>
          </div>
        </div>
        <Button btnStyle="contact" clicked={props.buttonClicked}>
          Contactez-Nous
        </Button>
        {/* Wood Cleaning Section */}
        <div id="wood" className="sections__wood">
          <div className="sections__woodcontent">
            <h3>Nettoyage et Entretien de Parquet</h3>
            <p>
              SPS peut entreprendre tous les niveaux d’entretien du parquet.
            </p>
            <br></br>
            <p>
              Notre service de ponçage et traitement de parquet redonnera à
              votre plancher sa beauté d’origine. Pour protéger votre sol, nous
              appliquons trois couches ou plus de vernis de haute qualité. Cela crée
              une barrière protectrice entre votre plancher et les facteurs
              potentiellement endommageantes tels que les meubles, la saleté et
              l’eau. En entretenant régulièrement votre sol, vous pouvez éviter
              les rénovations et d’autres travaux coûteux à long terme.
            </p>
            <br></br>
            <p>
              Après avoir scellé et protégé votre sol, nous offrons un service
              de polissage qui fera ressortir la beauté
              naturelle du bois.
            </p>
          </div>
        </div>
        <Button btnStyle="contact" clicked={props.buttonClicked}>
          Contactez-Nous
        </Button>
        {/* Stone Cleaning Section */}
        <div id="stone" className="sections__stone">
          <div className="sections__stonecontent">
            <h3>Nettoyage et Scellement de Pierre Naturel</h3>
            <p>
              Un plancher en pierre naturelle peut être poreux résultant en
              l'absorption et l'accumulation des saletées et impuretés au fil du
              temps. Nous offrons un service de nettoyage en profondeur
              spécifiquement pour les plancher de ce type qui nous permet
              d'extraire toutes les accumulations de saleté et de sable et de
              redonner à votre sol son éclat d'origine.
            </p>
            <br></br>
            <p>
              Après avoir effectué un nettoyage en profondeur, nous appliquons
              un scellant conçu pour protéger votre plancher contre l'absorption de
              futures saletées et impuretés.
            </p>
          </div>
        </div>
        <Button btnStyle="contact" clicked={props.buttonClicked}>
          Contactez-Nous
        </Button>
        {/* Tile Cleaning Section */}
        <div id="tile" className="sections__tiles">
          <div className="sections__tilescontent">
            <h3>Nettoyage et Scellement de Sol Carrelé</h3>
            <p>
              Le carrelage peut se trouver dans plusieurs pièces de votre
              propriétée telles que votre Cuisine, Salle de Bain et autres
              pièces. Généralement, ces pièces peuvent accumuler plusieurs
              saletées, surtout dans une cuisine professionnelle.
            </p>
            <br></br>
            <p>
              SPS peut nettoyer en profondeur les sols carrelés et si nécessaire
              sceller les joints. Cela créer une barrière pour arrêter
              l'accumulation de saleté dans les joints.
            </p>
          </div>
        </div>
        <Button btnStyle="contact" clicked={props.buttonClicked}>
          Contactez-Nous
        </Button>
        {/* Safety Cleaning Section */}
        <div id="safety" className="sections__safety">
          <div className="sections__safetycontent">
            <h3>PVC, Linoléum et Plancher de Sécurité</h3>
            <p>
              Les Planchers de Sécurités tel que le PVC et le Linoléum sont des
              options durables pour couvrir les zones de haute fréquentation. Ces types
              de sols couvrent des zones où la propreté et la prévention de
              risque d'accidents sont importants.
            </p>
            <br></br>
            <p>
              SPS propose des options de nettoyage et de scellage pour ces types
              de sol.
            </p>
            <br></br>
            <p>
              Il n'est pas recommandé de polir les sol de sécurité car cela peut
              réduire les propriétées anti-dérapantes de votre sol. Le nettoyage
              en profondeurpeut être entrepris pour éliminer les saletées
              incrustées dans votre plancher.
            </p>
          </div>
        </div>
        {/* Hard Cleaning Process */}
        <div className="sections__hardprocess">
          <div className="sections__hardleft">
            <div className="sections__hardimage">
              <img
                src={hardImage}
                alt="Procédure de Nettoyage et Scellage des Sols Durs"
              />
            </div>
            <div className="sections__harddisclaimer">
              <p>
                <em>
                  Tous les produits utilisés sont sans danger pour les enfants,
                  les animaux domestiques et l’environnement. Chaque membre de
                  notre équipe est bien formé aux techniques de nettoyage afin
                  de fournir un résultat supérieur et efficace.
                </em>
              </p>
            </div>
          </div>

          <div className="sections__hardlist">
            <h3>Procédure de Nettoyage et Scellage des Sols Durs</h3>
            <ol>
              <li onMouseOver={() => setHardImage(hardImages[0])}>
                <h4>Aspiration à Sec</h4>
              </li>
              <li onMouseOver={() => setHardImage(hardImages[0])}>
                <h4>Récurage Profond</h4>
              </li>
              <li onMouseOver={() => setHardImage(hardImages[0])}>
                <h4>Aspiration avec Détergent</h4>
              </li>
              <li onMouseOver={() => setHardImage(hardImages[0])}>
                <h4>Scellage</h4>
              </li>
              <li onMouseOver={() => setHardImage(hardImages[0])}>
                <h4>Polissage</h4>
              </li>
            </ol>
          </div>
        </div>
        <Button btnStyle="contact" clicked={props.buttonClicked}>
          Contactez-Nous
        </Button>
        <div className="sections__testimonials">
          <h3>Testémonies</h3>
          <div className="sections__testimonial">
            <img src={testImg1} alt="Alpine Infusion Logo Banner" />
            <p>
              <em>
                “Alpine Infusion has used SPS for the last 3 years, they have
                always provided us with an excellent service. From pre-season
                chalet set ups to emergency mid-season stain removals, the
                knowledge that Rob has of stain removal and carpet cleaning is
                unparalleled and invaluable to our company.”
              </em>
            </p>
            <p>
              <RemoveIcon />
              Lucy Fisher Operations Manager Alpine Infusion
              www.alpineinfusion.com
            </p>
          </div>
          <div id="testimonials" className="sections__testimonial">
            <img src={testImg2} alt="A.M.S. Logo Banner" />
            <p>
              <em>
                “SPS have always provided a good and professional service, they
                do an excellent job at a very reasonable price and we will
                continue to use them.”
              </em>
            </p>
            <p>
              <RemoveIcon />
              Jamie Tingle A.M.S Alpine Management Service www.amsfrance.com
            </p>
          </div>
          <div className="sections__testimonial">
            <img src={testImg3} alt="Alpine Shine Logo Banner" />
            <p>
              <em>
                “I would like to take this opportunity to say “Thank You” to
                Rob, for the excellent service that he has provided to us as
                well as our clients. He has assisted us with professional carpet
                and upholstery cleaning at a number of the properties we look
                after in the 3 valleys and on every occasion provided a
                professional and efficient service. He was always careful with
                furniture, used professional machinery and the quality of work
                was excellent! Many of the carpets looked almost brand new after
                heavy use through busy seasons. I have recommended SPS Carpet
                Cleaning to many of my clients in the past and will continue to
                do so in the future.”
              </em>
            </p>
            <p>
              <RemoveIcon />
              Jessica Doyle, Managing Director, Alpine Shine Ltd
              www.alpine-shine.com
            </p>
          </div>
          <div className="sections__testimonial">
            <img src={testImg4} alt="Consensio Logo Banner" />
            <p>
              <em>
                “Having used Rob in my 5* chalet on numerous occasions, I have
                found his customer service and actual results to be top notch.
                Highly recommended.”
              </em>
            </p>
            <p>
              <RemoveIcon />
              David Austen-Adams Consensio Holidays www.consensioholidays.co.uk
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="sections">
        {/* About Section */}
        <div id="about" className="sections__about">
          <div className="sections__aboutcontent">
            <h3>‘Preserving the Image of your Establishment.’</h3>
            <p>
              Founded in Méribel in 2012, SPS specialises in professional
              carpet, upholstery and hard-floor cleaning for the Savoie region.
              We offer high-level cleaning services for a multitude of indoor
              surfaces including carpets, rugs, sofas & upholstery, curtains,
              wood floors, tiled floors and natural stone floors.
            </p>
            <br></br>
            <p>
              Our team is fully trained, insured and equipped with the best
              machinery and cleaning products on the market. We pride ourselves
              in providing an exceptional service to preserve the image and
              integrity of your chalet, home or establishment.
            </p>
            <br></br>
            <p>
              SPS is committed to sourcing environmentally friendly products to
              reduce the impact on our planet.
            </p>
          </div>
        </div>

        {/*Carpet cleaning Section*/}
        <div id="carpet" className="sections__carpet">
          <div className="sections__carpetcontent">
            <h3>Carpet Cleaning</h3>
            <p>
              Over time, textiles and tapestry accumulate dust, body oils, skin
              particles and other pollutants that affect the look and feel of
              your Carpets and Furniture while reducing their longevity. Some
              pollutants could also be harmful to your health.
            </p>
            <br></br>
            <p>
              At SPS, we use a hot-water extraction technique that performs a
              deep fibre clean, extracting dirt, grit and other harmful
              particles imbedded in the fabrics. Our services restore your
              Carpets and Furniture to their original Clean, Fresh and Stain-Free
              appearance. We take extra care to maintain the integrity of the
              fibres without leaving behind any chemical residue or damaging the
              fabrics.
            </p>
            <br></br>
            <p>
              Our specialists are professionally trained and equipped with state
              of the art carpet cleaning machinery and deep tissue cleaning
              products. Our machines operate at an incredibly-high pressure in
              order to extract water and maintain drying time to a bare minimum.
              We use manufacturer recommended and environmentally sustainable
              cleaning detergents during the process.
            </p>
          </div>
        </div>

        {/* Stain Removal Section */}
        <div id="stain" className="sections__stains">
          <div className="sections__stainscontent">
            <h3>Stain Removal</h3>
            <p>
              No carpet is completely safe from liquid spills, little
              accidents always happen. Your best chance is to act quickly.
              Without doubt, the longer stains remain on your carpet or
              upholstery, the harder they become to remove. Most carpet stains
              can be dealt with, however, stubborn carpet stains will probably
              require proven stain removal solutions.
            </p>
            <br></br>
            <p>
              We possess the knowledge, skill and understanding to remove almost
              all stains and will examine the affected areas in question and
              provide a no-obligation estimate based on your stained areas.
            </p>
          </div>
        </div>
        {/* Carpet Cleaning Process */}
        <div className="sections__softprocess">
          <div className="sections__softleft">
            <div className="sections__softimage">
              <img
                src={softImage}
                alt="Carpet and Upholster Cleaning Process"
              />
            </div>
            <div className="sections__softdisclaimer">
              <p>
                <em>
                  All products used during the process are safe for children,
                  pets and the environment. Every member of our team is well
                  trained with cleaning techniques, delivering efficient and
                  effective results.
                </em>
              </p>
            </div>
          </div>

          <div className="sections__softlist">
            <h3>Carpets & Upholstery Cleaning Process</h3>
            <ol>
              <li onMouseOver={() => setSoftImage(softImages[0])}>
                <h4>Determine the Fabric Type</h4>
              </li>
              <li onMouseOver={() => setSoftImage(softImages[0])}>
                <h4>Dry Vacuuming</h4>
              </li>
              <li onMouseOver={() => setSoftImage(softImages[2])}>
                <h4>Spotting, Edging, Pre-Spray and Scrub</h4>
              </li>
              <li onMouseOver={() => setSoftImage(softImages[1])}>
                <h4>Hot Water Extraction (Deep Steam Cleaning)</h4>
              </li>
              <li onMouseOver={() => setSoftImage(softImages[3])}>
                <h4>Drying</h4>
              </li>
            </ol>
          </div>
        </div>
        <Button btnStyle="contact" clicked={props.buttonClicked}>
          Contact Us
        </Button>
        {/* Wood Cleaning Section */}
        <div id="wood" className="sections__wood">
          <div className="sections__woodcontent">
            <h3>Wood Floor Cleaning & Maintenance</h3>
            <p>SPS can undertake all levels of wood floor maintenance.</p>
            <br></br>
            <p>
              Our wood floor Sanding and Treating service will return your floor
              to its original beauty. To protect your floor, we apply three layers
              or more of high-grade polish. This creates a protective barrier
              between your floor and possible damaging factors such as
              furniture, dirt and water. By regularly polishing and maintaining
              your floor you can avoid sanding, renovating and other expensive
              long-term costs.
            </p>
            <br></br>
            <p>
              After Sealing and Protecting your floor, we offer a buff and
              polish service which will bring out the natural beauty of the
              wood.
            </p>
          </div>
        </div>
        <Button btnStyle="contact" clicked={props.buttonClicked}>
          Contact Us
        </Button>
        {/* Stone Cleaning Section */}
        <div id="stone" className="sections__stone">
          <div className="sections__stonecontent">
            <h3>Stone Floor Cleaning & Sealing</h3>
            <p>
              Natural Stones are absorbant porous minerals which can allow
              build-ups of dirt over time. We offer a deep-clean service
              specific to Natural Stone allowing us to extract all
              build-ups of dirt and grit and returning your floor to its
              original beauty.
            </p>
            <br></br>
            <p>
              After performing a deep-clean, we then apply a sealant designed to
              protect your floor from absorbing future harmful dirt and
              particles.
            </p>
          </div>
        </div>
        <Button btnStyle="contact" clicked={props.buttonClicked}>
          Contact Us
        </Button>
        {/* Tile Cleaning Section */}
        <div id="tile" className="sections__tiles">
          <div className="sections__tilescontent">
            <h3>Tile Cleaning & Sealing</h3>
            <p>
              Tiled flooring can be found in a number of areas of your property
              such as your Kitchen, Toilets and other rooms. Generally these
              rooms can build up lots of dirt, especially in a professional
              kitchen.
            </p>
            <br></br>
            <p>
              SPS can deep clean tiled floors and if required seal the grout
              between the tiles. This will create a barrier to stop a build up
              of dirt from embedding itself in the grout.
            </p>
          </div>
        </div>
        <Button btnStyle="contact" clicked={props.buttonClicked}>
          Contact Us
        </Button>
        {/* Safety Cleaning Section */}
        <div id="safety" className="sections__safety">
          <div className="sections__safetycontent">
            <h3>PVC, Linoleum and Safety Floors</h3>
            <p>
              PVC, linoleum and safety flooring are durable options for covering
              high-traffic areas. These types of floors cover areas where
              cleanliness and the prevention of slip hazards is important.
            </p>
            <br></br>
            <p>
              SPS offers cleaning and sealing options for these types of
              flooring.
            </p>
            <br></br>
            <p>
              It is not recommended to polish safety flooring as this can reduce
              the non-slip properties of your floor. Deep cleaning can be
              undertaken to eliminate dirt built up embedded in your safety
              flooring.
            </p>
          </div>
        </div>
        {/* Hard Cleaning Process */}
        <div className="sections__hardprocess">
          <div className="sections__hardleft">
            <div className="sections__hardimage">
              <img
                src={hardImage}
                alt="Hard Floor Cleaning and Treatment Process"
              />
            </div>
            <div className="sections__harddisclaimer">
              <p>
                <em>
                  All products used during the process are safe for children,
                  pets and the environment. Every member of our team is well
                  trained in cleaning techniques to deliver efficient and
                  effective results.
                </em>
              </p>
            </div>
          </div>

          <div className="sections__hardlist">
            <h3>Hard Floor Cleaning & Sealing Process</h3>
            <ol>
              <li onMouseOver={() => setHardImage(hardImages[0])}>
                <h4>Dry Vacuuming</h4>
              </li>
              <li onMouseOver={() => setHardImage(hardImages[0])}>
                <h4>Deep Scrub</h4>
              </li>
              <li onMouseOver={() => setHardImage(hardImages[0])}>
                <h4>Wet Vacuuming</h4>
              </li>
              <li onMouseOver={() => setHardImage(hardImages[0])}>
                <h4>Sealing</h4>
              </li>
              <li onMouseOver={() => setHardImage(hardImages[0])}>
                <h4>Polishing & Buffing</h4>
              </li>
            </ol>
          </div>
        </div>
        <Button btnStyle="contact" clicked={props.buttonClicked}>
          Contact Us
        </Button>
        <div className="sections__testimonials">
          <h3>Testimonials</h3>
          <div className="sections__testimonial">
            <img src={testImg1} alt="Alpine Infusion Logo Banner" />
            <p>
              <em>
                “Alpine Infusion has used SPS for the last 3 years, they have
                always provided us with an excellent service. From pre-season
                chalet set ups to emergency mid-season stain removals, the
                knowledge that Rob has of stain removal and carpet cleaning is
                unparalleled and invaluable to our company.”
              </em>
            </p>
            <p>
              <RemoveIcon />
              Lucy Fisher Operations Manager Alpine Infusion
              www.alpineinfusion.com
            </p>
          </div>
          <div id="testimonials" className="sections__testimonial">
            <img src={testImg2} alt="A.M.S. Logo Banner" />
            <p>
              <em>
                “SPS have always provided a good and professional service, they
                do an excellent job at a very reasonable price and we will
                continue to use them.”
              </em>
            </p>
            <p>
              <RemoveIcon />
              Jamie Tingle A.M.S Alpine Management Service www.amsfrance.com
            </p>
          </div>
          <div className="sections__testimonial">
            <img src={testImg3} alt="Alpine Shine Logo Banner" />
            <p>
              <em>
                “I would like to take this opportunity to say “Thank You” to
                Rob, for the excellent service that he has provided to us as
                well as our clients. He has assisted us with professional carpet
                and upholstery cleaning at a number of the properties we look
                after in the 3 valleys and on every occasion provided a
                professional and efficient service. He was always careful with
                furniture, used professional machinery and the quality of work
                was excellent! Many of the carpets looked almost brand new after
                heavy use through busy seasons. I have recommended SPS Carpet
                Cleaning to many of my clients in the past and will continue to
                do so in the future.”
              </em>
            </p>
            <p>
              <RemoveIcon />
              Jessica Doyle, Managing Director, Alpine Shine Ltd
              www.alpine-shine.com
            </p>
          </div>
          <div className="sections__testimonial">
            <img src={testImg4} alt="Consensio Logo Banner" />
            <p>
              <em>
                “Having used Rob in my 5* chalet on numerous occasions, I have
                found his customer service and actual results to be top notch.
                Highly recommended.”
              </em>
            </p>
            <p>
              <RemoveIcon />
              David Austen-Adams Consensio Holidays www.consensioholidays.co.uk
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default Sections;
